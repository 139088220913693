

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import GlobalSearchFilteredLocalisation from '~/components/lists/items/GlobalSearchFilteredLocalisation.vue';
import ErrorCard from "~/components/cards/ErrorCard.vue";

@Component({
  components: { ErrorCard, GlobalSearchFilteredLocalisation },
  computed: {
    ...mapState('explorer', [
      'searchedLocs',
      'searchedLocsError',
      'isSearchingLoc',
    ]),
  },
})
export default class GlobalSearchLocalisation extends Vue {
  @Prop(String) searchText: '';

  isSearchingLoc: boolean;
  searchedLocs: Types.ReverseLocalisation[];

  get filteredLocalisations () {
    if (this.searchText) {
      return this.searchedLocs;
    }
    return [];
  }
}
