import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { apiQueryBuilder } from '~/utils/common.js';

// =================================== STATE ===================================

export const state: Types.ExplorerState = () => ({
  displayGlobalSearchInHeader: false,
  globalSearchTab: 'destination',
  localisationTab: 'destinations',
  displayLocalisationMap: true,
  userCoords: null,
  searchedLocs: [],
  searchedLocsError: null,
  isSearchingLoc: false,
  destinationTab: 'routes',
  mobileLocalisationFiltersModal: '',
  event: {},
  destination: {},
  provider: {},
  touristOffice: {},
  destinationEvents: [],
  destinationPartners: [],
  destinationProviders: {},
  destinationProvidersMap: [],
  destinationProvidersSuggestions: [],
  destinationContacts: [],
  destinationTourists: [],
  destinationRoutes: {},
  destinationRoutesWithTracks: [],
  route: {},
  destinationWeather: null,
  destinationsResults: [],
  routesResults: [],
  eventsResults: [],
  providersResults: [],
  sports: [],
  destinationSuggestions: {},
  destinationsHighlighted: [],
  destinations: [],
  routes: [],
  events: [],
  labels: [],
  routesList: {},
  routesMap: [],
  destinationsList: {},
  destinationsMap: [],
  eventsList: {},
  eventsMap: [],
  eventsSuggestions: [],
  destinationEventsSuggestions: [],
  mappingOfSuggestedDestinations: [],
  destinationRoutesFilters: {},
  destinationEventsFilters: {},
  destinationProvidersFilters: {},
  destinationDestinationFilters: {},
  routesSuggestions: [],
  forceExplorerCalls: false,
  hasChangedLanguage: false,
  explorerBounds: {},
  hoveredRoutes: [],
});

export type RootState = ReturnType<typeof state>

// ================================== GETTERS ==================================

export const getters: GetterTree<Types.ExplorerState, RootState> = {
  // returns labels grouped by map [0, 1] : 0 as parent, 1 as children
  labelsForModelGroupedByParent: (theState: any) => (model: string) => {
    const labelsForModel = theState.labels
      ?.filter((label: Types.Label) => (label.authorized_models ?? []).includes(model)) ??
      [];

    return new Map(
      labelsForModel
        .filter((label: Types.Label) => label.parent_id === null)
        .map((parentLabel: Types.Label) => [
          parentLabel,
          labelsForModel.filter((label: Types.Label) => label.parent_id === parentLabel.id),
        ]),
    );
  },
};

// ================================= MUTATIONS =================================

export const mutations: MutationTree<RootState> = {
  SET_EXPLORER_BOUNDS (state, payload) {
    state.explorerBounds = payload;
  },
  SET_HAS_CHANGED_LANGUAGE (state, payload) {
    state.hasChangedLanguage = payload;
  },
  SET_EXPLORER_FORCE_CALLS (state, payload) {
    state.forceExplorerCalls = payload;
  },
  SET_GLOBAL_SEARCH_TAB (state, payload: string) {
    state.globalSearchTab = payload;
  },
  SET_DISPLAY_SEARCH_IN_MENU (state, payload: boolean) {
    state.displayGlobalSearchInHeader = payload;
  },
  SET_LOCALISATION_TAB (state, payload: string) {
    state.localisationTab = payload || 'destinations';
  },
  SET_LOCALISATION_MAP_DISPLAY (state, payload: boolean) {
    state.displayLocalisationMap = payload;
  },
  SET_USER_COORDS (state, payload) {
    state.userCoords = payload;
  },
  SET_SEARCHED_LOCALISATIONS (state, payload: any) {
    state.searchedLocs = payload.features;
  },
  SET_SEARCHED_LOCALISATIONS_ERROR (state, payload: any) {
    state.searchedLocsError = payload;
  },
  SET_IS_SEARCHING_LOC (state, payload: boolean) {
    state.isSearchingLoc = payload;
  },
  SET_DESTINATION_TAB (state, payload: string) {
    state.destinationTab = payload || 'routes';
  },
  SET_MOBILE_LOCALISATION_FILTERS_MODAL (state, payload: string) {
    state.mobileLocalisationFiltersModal = payload;
  },
  SET_EVENT (state, payload) {
    state.event = payload;
  },
  SET_DESTINATION (state, payload) {
    state.destination = payload;
  },
  SET_ROUTE (state, payload) {
    state.route = payload;
  },
  SET_PROVIDER (state, payload) {
    state.provider = payload;
  },
  SET_TOURIST_OFFICE (state, payload) {
    state.touristOffice = payload;
  },
  SET_DESTINATION_EVENTS (state, payload) {
    state.destinationEvents = payload;
  },
  SET_APPEND_DESTINATION_EVENT_LIST (state, payload: Types.EventList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.EventList) => {
      state.destinationEvents.items.push(item);
    });
  },
  SET_LABELS (state, payload) {
    state.labels = payload;
  },
  SET_DESTINATION_EVENTS_SUGGESTIONS (state, payload) {
    state.destinationEventsSuggestions = payload;
  },
  SET_DESTINATION_PARTNERS (state, payload) {
    state.destinationPartners = payload;
  },
  SET_DESTINATION_PROVIDERS (state, payload) {
    state.destinationProviders = payload;
  },
  SET_APPEND_DESTINATION_PROVIDER_LIST (state, payload: Types.EventList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.EventList) => {
      state.destinationProviders.items.push(item);
    });
  },
  SET_DESTINATION_PROVIDERS_MAP (state, payload) {
    state.destinationProvidersMap = payload;
  },
  SET_DESTINATION_PROVIDERS_SUGGESTIONS (state, payload) {
    state.destinationProvidersSuggestions = payload;
  },
  SET_DESTINATION_CONTACTS (state, payload) {
    state.destinationContacts = payload;
  },
  SET_DESTINATION_TOURISTS (state, payload) {
    state.destinationTourists = payload;
  },
  SET_DESTINATION_ROUTES (state, payload) {
    state.destinationRoutes = payload;
  },
  SET_APPEND_DESTINATION_ROUTES_LIST (state, payload: Types.DestinationList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.DestinationList) => {
      state.destinationRoutes.items.push(item);
    });
  },
  SET_DESTINATION_ROUTES_WITH_TRACKS (state, payload) {
    state.destinationRoutesWithTracks = payload;
  },
  SET_DESTINATION_WEATHER (state, payload) {
    state.destinationWeather = payload;
  },
  SET_DESTINATION_RESULTS (state, payload) {
    state.destinationsResults = payload;
  },
  SET_ROUTES_RESULTS (state, payload) {
    state.routesResults = payload;
  },
  SET_EVENTS_RESULTS (state, payload) {
    state.eventsResults = payload;
  },
  SET_PROVIDERS_RESULTS (state, payload) {
    state.providersResults = payload;
  },
  SET_SPORTS (state, payload) {
    state.sports = payload;
  },
  SET_DESTINATION_SUGGESTIONS (state, payload) {
    state.destinationSuggestions = payload;
  },
  SET_DESTINATIONS_HIGHLIGHTED (state, payload) {
    state.destinationsHighlighted = payload;
  },
  SET_DESTINATIONS (state, payload) {
    state.destinations = payload;
  },
  SET_ROUTES (state, payload) {
    state.routes = payload;
  },
  SET_EVENTS (state, payload) {
    state.events = payload;
  },
  SET_ROUTES_LIST (state, payload) {
    state.routesList = payload;
  },
  SET_APPEND_ROUTES_LIST (state, payload: Types.RouteList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.Route) => {
      state.routesList.items.push(item);
    });
  },
  SET_ROUTES_MAP (state, payload) {
    state.routesMap = payload;
  },
  SET_DESTINATION_LIST (state, payload) {
    state.destinationsList = payload;
  },
  SET_APPEND_DESTINATION_LIST (state, payload: Types.DestinationList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.DestinationItem) => {
      state.destinationsList.items.push(item);
    });
  },
  SET_DESTINATION_MAP (state, payload) {
    state.destinationsMap = payload;
  },
  SET_EVENT_LIST (state, payload) {
    state.eventsList = payload;
  },
  SET_APPEND_EVENT_LIST (state, payload: Types.EventList) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.Event) => {
      state.eventsList.items.push(item);
    });
  },
  SET_EVENT_MAP (state, payload) {
    state.eventsMap = payload;
  },
  SET_EVENTS_SUGGESTIONS (state, payload) {
    state.eventsSuggestions = payload;
  },
  SET_MAPPING_OF_SUGGESTED_DESTINATIONS (state, payload) {
    state.mappingOfSuggestedDestinations = payload;
  },
  SET_DESTINATION_MAP_ROUTES_FILTERS (state, payload) {
    state.destinationRoutesFilters = payload;
  },
  SET_DESTINATION_MAP_EVENTS_FILTERS (state, payload) {
    state.destinationEventsFilters = payload;
  },
  SET_DESTINATION_MAP_PROVIDERS_FILTERS (state, payload) {
    state.destinationProvidersFilters = payload;
  },
  SET_DESTINATION_MAP_DESTINATIONS_FILTERS (state, payload) {
    state.destinationDestinationFilters = payload;
  },
  SET_ROUTES_SIMILAR (state, payload) {
    state.routesSuggestions = payload;
  },
  SET_HOVERED_ROUTES (state, payload) {
    state.hoveredRoutes.push(payload);
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
  async getSearchedLocalisations (ctx, payload) {
    ctx.commit('SET_IS_SEARCHING_LOC', true);
    ctx.commit('SET_SEARCHED_LOCALISATIONS_ERROR', null);

    try {
      const { data } = await this.$axios.get(`public/geocoder?q=${payload.text}&limit=3&types=municipality&lang=${payload.lang}`);

      ctx.commit('SET_SEARCHED_LOCALISATIONS', data);
      return data;
    } catch (error) {
      ctx.commit('SET_SEARCHED_LOCALISATIONS_ERROR', error);
    } finally {
      ctx.commit('SET_IS_SEARCHING_LOC', false);
    }
  },

  async getSearchedLocalisation (ctx, payload) {
    const { data } = await this.$axios.get(`public/geocoder?q=${payload.text}&limit=3&types=municipality&lang=${payload.lang}`);
    return data ? data?.features[0] : null;
  },

  getDestinationWeather (ctx, payload) {
    return this.$axios.get(
      `https://api.weatherapi.com/v1/forecast.json?key=013568fee884440bac1134843211009&q=${payload.lat},${payload.long}&days=2&aqi=no&alerts=no`,
    )
      .then((result) => {
        ctx.commit('SET_DESTINATION_WEATHER', result.data);
      });
  },

  async getEvent (ctx, payload) {
    const { data } = await this.$axios.get(`/public/events/${payload.id}?lang=${payload.lang}`);

    if (payload.appendToRoutesList) {
      ctx.commit('SET_APPEND_EVENT_LIST', { items: [data] });
    } else if (payload.appendToDestinationEventsList) {
      ctx.commit('SET_APPEND_DESTINATION_EVENT_LIST', { items: [data] });
    } else {
      ctx.commit('SET_EVENT', data);
    }
  },

  async getEventPreview (ctx, payload) {
    const { data } = await this.$axios.get(`/public/events/${payload.id}/preview/${payload.token}?lang=${payload.lang}`);

    ctx.commit('SET_EVENT', data);
  },

  async getDestination (ctx, payload) {
    const { data } = await this.$axios.get(`/public/destinations/${payload.id}?lang=${payload.lang}`);

    if (payload.appendToRoutesList) {
      ctx.commit('SET_APPEND_DESTINATION_LIST', { items: [data] });
    } else {
      ctx.commit('SET_DESTINATION', data);
    }
  },

  async getDestinationPreview (ctx, payload) {
    const { data } = await this.$axios.get(`/public/destinations/${payload.id}/preview/${payload.token}?lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION', data);
  },

  async getProvider (ctx, payload) {
    const { data } = await this.$axios.get(`/public/service_providers/${payload.id}?lang=${payload.lang}`);

    if (payload.appendToRoutesList) {
      ctx.commit('SET_APPEND_DESTINATION_PROVIDER_LIST', { items: [data] });
    } else {
      ctx.commit('SET_PROVIDER', data);
    }
  },

  async getProviderPreview (ctx, payload) {
    const { data } = await this.$axios.get(`/public/service_providers/${payload.id}/preview/${payload.token}?lang=${payload.lang}`);

    ctx.commit('SET_PROVIDER', data);
  },

  async getTouristOffice (ctx, payload) {
    const { data } = await this.$axios.get(`/public/tourist_offices/${payload.id}?lang=${payload.lang}`);

    ctx.commit('SET_TOURIST_OFFICE', data);
  },

  async getTouristOfficePreview (ctx, payload) {
    const { data } = await this.$axios.get(`/public/tourist_offices/${payload.id}/preview/${payload.token}?lang=${payload.lang}`);

    ctx.commit('SET_TOURIST_OFFICE', data);
  },

  async getRoute (ctx, payload) {
    const { data } = await this.$axios.get(`/public/routes/${payload.id}?lang=${payload.lang}`);
    if (payload.appendToRoutesList) {
      ctx.commit('SET_APPEND_ROUTES_LIST', { items: [data] });
    } else if (payload.appendToDestinationRoutesList) {
      ctx.commit('SET_APPEND_DESTINATION_ROUTES_LIST', { items: [data] });
    } else {
      ctx.commit('SET_ROUTE', data);
    }
  },

  async getRoutePreview (ctx, payload) {
    const { data } = await this.$axios.get(`/public/routes/${payload.id}/preview/${payload.token}?lang=${payload.lang}`);

    ctx.commit('SET_ROUTE', data);
  },

  async getLabels (ctx, payload) {
    const { data } = await this.$axios.get(`/public/labels?lang=${payload.lang}`);

    ctx.commit('SET_LABELS', data);

    return data;
  },

  async getDestinationEvents (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_DESTINATION_EVENTS', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_DESTINATION_EVENT_LIST', data);
    } else {
      ctx.commit('SET_DESTINATION_EVENTS', data);
    }

    return data;
  },

  async getDestinationEventsSuggestions (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events/suggestions${query}`);

    ctx.commit('SET_DESTINATION_EVENTS_SUGGESTIONS', data);
    return data;
  },

  async getEventsSimilar (ctx, payload) {
    const { data } = await this.$axios.get(`/public/events/${payload.id}/similar?lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION_EVENTS_SUGGESTIONS', data);
    return data;
  },

  async getDestinationPartners (ctx, payload) {
    const { data } = await this.$axios.get(`/public/partners/logos?destination_id=${payload.id}`);

    ctx.commit('SET_DESTINATION_PARTNERS', data);
    return data;
  },

  async getDestinationProvidersAllListed (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/service_providers${query}`);

    ctx.commit('SET_DESTINATION_PROVIDERS', data);

    return data;
  },

  async getDestinationProviders (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/service_providers/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_DESTINATION_PROVIDERS', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_DESTINATION_PROVIDER_LIST', data);
    } else {
      ctx.commit('SET_DESTINATION_PROVIDERS', data);
    }
    return data;
  },

  async getDestinationProvidersMap (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/service_providers/map${query}`);

    ctx.commit('SET_DESTINATION_PROVIDERS_MAP', data);

    return data;
  },

  async getDestinationProvidersSuggestions (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/service_providers/suggestions${query}`);

    ctx.commit('SET_DESTINATION_PROVIDERS_SUGGESTIONS', data);
    return data;
  },

  async getProvidersSimilar (ctx, payload) {
    const { data } = await this.$axios.get(`/public/service_providers/${payload.id}/similar?lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION_PROVIDERS_SUGGESTIONS', data);
    return data;
  },

  async getDestinationContacts (ctx, payload) {
    const { data } = await this.$axios.get(`/public/contacts/suggestions?destination_id=${payload.id}&lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION_CONTACTS', data);
    return data;
  },

  async getDestinationTourists (ctx, payload) {
    const { data } = await this.$axios.get(`/public/tourist_offices/suggestions?destination_id=${payload.id}&lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION_TOURISTS', data);
    return data;
  },

  async getTouristsSimilar (ctx, payload) {
    const { data } = await this.$axios.get(`/public/tourist_offices/${payload.id}/similar?lang=${payload.lang}`);

    ctx.commit('SET_DESTINATION_TOURISTS', data);
    return data;
  },

  async getDestinationRoutes (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/routes/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_DESTINATION_ROUTES', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_DESTINATION_ROUTES_LIST', data);
    } else {
      ctx.commit('SET_DESTINATION_ROUTES', data);
    }

    return data;
  },

  async getDestinationRoutesWithTracks (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/routes/with-track${query}`);

    ctx.commit('SET_DESTINATION_ROUTES_WITH_TRACKS', data);

    return data;
  },

  async getSimilarRoutes (ctx, payload) {
    const { data } = await this.$axios.get(`/public/routes/${payload.id}/similar?lang=${payload.lang}`);

    ctx.commit('SET_ROUTES_SIMILAR', data);

    return data;
  },

  async getDestinations (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/destinations${query}`);

    ctx.commit('SET_DESTINATIONS', data);

    return data;
  },

  async getDestinationsList (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/destinations/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_DESTINATION_LIST', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_DESTINATION_LIST', data);
    } else {
      ctx.commit('SET_DESTINATION_LIST', data);
    }

    return data;
  },

  async getDestinationsMap (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/destinations/map${query}`);

    ctx.commit('SET_DESTINATION_MAP', data);

    return data;
  },

  async getRoutes (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/routes${query}`);

    ctx.commit('SET_ROUTES', data);

    return data;
  },

  async getRoutesList (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/routes/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_ROUTES_LIST', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_ROUTES_LIST', data);
    } else {
      ctx.commit('SET_ROUTES_LIST', data);
    }

    return data;
  },

  async getRoutesMap (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/routes/map${query}`);

    ctx.commit('SET_ROUTES_MAP', data);

    return data;
  },

  async getEvents (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events${query}`);

    ctx.commit('SET_EVENTS', data);

    return data;
  },

  async getEventsList (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events/list${query}`);

    if (payload.reset_append) {
      ctx.commit('SET_EVENT_LIST', data);
      return;
    }

    if (payload.has_more) {
      ctx.commit('SET_APPEND_EVENT_LIST', data);
    } else {
      ctx.commit('SET_EVENT_LIST', data);
    }

    return data;
  },

  async getEventsMap (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events/map${query}`);

    ctx.commit('SET_EVENT_MAP', data);

    return data;
  },

  async getEventsSuggestions (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/events/suggestions${query}`);

    ctx.commit('SET_EVENTS_SUGGESTIONS', data);

    return data;
  },

  async searchDestinationsByText (ctx, payload) {
    ctx.commit('SET_IS_SEARCHING_LOC', true);

    try {
      const { data } = await this.$axios.get(`/public/destinations/search/by-text?q=${payload.content}&lang=${payload.lang}`);

      ctx.commit('SET_DESTINATION_RESULTS', data);
      return data;
    } finally {
      ctx.commit('SET_IS_SEARCHING_LOC', false);
    }
  },

  async searchRoutesByText (ctx, payload) {
    ctx.commit('SET_IS_SEARCHING_LOC', true);

    try {
      const { data } = await this.$axios.get(`/public/routes/search/by-text?q=${payload.content}&lang=${payload.lang}`);

      ctx.commit('SET_ROUTES_RESULTS', data);
      return data;
    } finally {
      ctx.commit('SET_IS_SEARCHING_LOC', false);
    }
  },

  async searchEventsByText (ctx, payload) {
    ctx.commit('SET_IS_SEARCHING_LOC', true);

    try {
      const { data } = await this.$axios.get(`/public/events/search/by-text?q=${payload.content}&lang=${payload.lang}`);

      ctx.commit('SET_EVENTS_RESULTS', data);
      return data;
    } finally {
      ctx.commit('SET_IS_SEARCHING_LOC', false);
    }
  },

  async searchProvidersByText (ctx, payload) {
    ctx.commit('SET_IS_SEARCHING_LOC', true);

    try {
      const { data } = await this.$axios.get(`/public/service_providers/search/by-text?q=${payload.content}&lang=${payload.lang}`);

      ctx.commit('SET_PROVIDERS_RESULTS', data);
      return data;
    } finally {
      ctx.commit('SET_IS_SEARCHING_LOC', false);
    }
  },

  async getSports (ctx, payload) {
    const query: string = apiQueryBuilder(payload);

    const { data } = await this.$axios.get(`/public/sports${query}`);

    ctx.commit('SET_SPORTS', data);

    return data;
  },

  async getDestinationSuggestions (ctx, lang) {
    const { data } = await this.$axios.get(`/public/destinations/search/suggestions?lang=${lang}`);

    ctx.commit('SET_DESTINATION_SUGGESTIONS', data);

    return data;
  },

  async getDestinationsHighlighted (ctx, lang) {
    const { data } = await this.$axios.get(`/public/destinations/highlighted?lang=${lang}`);

    ctx.commit('SET_DESTINATIONS_HIGHLIGHTED', data);

    return data;
  },

  async downloadRouteGpx (ctx, routeId: number) {
    const { data } = await this.$axios.get(`/routes/${routeId}/track.gpx`);

    return data;
  },

  async getHoveredRoute (ctx, payload) {
    const { data } = await this.$axios.get(`/public/routes/${payload.id}?lang=${payload.lang}`);

    ctx.commit('SET_HOVERED_ROUTES', data);
  },
};
