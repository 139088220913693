























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { isObject } from '~/utils/common';

const Kind = {
  NONE: 'no-error',
  // Ideally, these errors should be intercepted but might not
  SERVER_SIMPLE: 'server-simple-error',
  SERVER_COMPLEX: 'server-complex-error',
  // Shouldn't happen in prod (client validation must prevent it)
  VALIDATION: 'validation-error',
  // Unhandled errors that indicate development mistakes
  UNKNOWN: 'unknown-error',
};

@Component
export default class ErrorCard extends Vue {
  @Prop() error: any;

  $refs: {
    errorBox: HTMLDivElement,
  };

  Kind = Kind;

  /* Detail of error to display based on this.error structure */
  get errorDetail () {
    const e = this.error;
    if (!e) return '';
    if (this.$nuxt.context.isDev) console.error('[ErrorCard]', e);
    return e.response ? (e.response.data.detail ? e.response.data.detail : e.response.data.message) : e.message;
  }

  /* Returns error type to display title and other infos in belowed methods */
  get errorKind () {
    if (isObject(this.error) && Object.keys(this.error).length === 0) return Kind.NONE;
    if (!this.error) return Kind.NONE;
    if (this.error.response && this.errorDetail) {
      if (Array.isArray(this.errorDetail)) return Kind.VALIDATION;
      if (isObject(this.errorDetail)) return Kind.SERVER_COMPLEX;
      return Kind.SERVER_SIMPLE;
    }
    // This means development error requiring investigation.
    return undefined;
  }

  /* Displays the good format of this.error title */
  get title () {
    switch (this.errorKind) {
      case Kind.NONE: return '';
      case Kind.SERVER_SIMPLE: return this.errorDetail;
      case Kind.SERVER_COMPLEX: return 'Error';
      case Kind.VALIDATION: return 'Form Error';
      case Kind.UNKNOWN:
      default:
        return 'Unexpected Error';
    }
  }

  /* Displays the good format of this.error description */
  get description () {
    switch (this.errorKind) {
      case Kind.NONE: return '';
      case Kind.SERVER_SIMPLE: return '';
      case Kind.SERVER_COMPLEX: return prettyComplexError(this.errorDetail);
      case Kind.VALIDATION: return prettyValidationError(this.errorDetail);
      case Kind.UNKNOWN:
      default:
        return 'An unknown error has occured. Please contact support for help.';
    }
  }

  /* Triggers visual effect when this.error prop is updated */
  @Watch('error')
  function () {
    this.applyVisualState();
  }

  /*
    Triggers visual effect on mounted in case the component wasn't
    already called
  */
  mounted () {
    this.applyVisualState();
  }

  /*
    Util methods to apply a visual state of errorCard and remove it after
    a timeout
  */
  applyVisualState () {
    const root: any = this.$el;
    const errorBox: HTMLDivElement = this.$refs.errorBox;
    if (!root || !errorBox) {
      throw new Error('Elements are not all ready. Should be executed after mounted hook');
    }
    if (this.error) {
      if (process.client) {
        window.requestAnimationFrame(() => {
          const height: string = `${this.$refs.errorBox.getBoundingClientRect().height}px`;
          root.style.height = height;
          root.style.removeProperty('margin'); // removes margin added by hideErrorCard
          root.classList.add('Shaker');
          window.setTimeout(() => root.classList.remove('Shaker'), 500);
        });
      }
    } else {
      root.style.height = 0;
      root.style.margin = 0; // only relevant if a margin is set on the component
    }
  }
}

/* Clean detail provided to bring more spaces in string */
function prettyValidationError (detail: any) {
  return detail.map((d: any) => `${d.loc.join('.')}: ${d.msg}`).join('\n');
}

/* Clean detail provided to bring more spaces in string */
function prettyComplexError (detail: any) {
  return Object.keys(detail).map(k => `${k}: ${detail[k]}`).join('\n');
}
